import React, { useEffect, useState } from "react";
import "./verkaufenBanner.scss";
import KaufunBanner from "../../../assets/images/verkaufen-banner.png";
import KaufunBanner1 from "../../../assets/images/mobile-banner1.png";
import PageArrow from "../../../assets/icons/full-page-arrow.svg";
import { NavLink } from "react-router-dom";
import AdsComponent from "../../../components/adComponent/adsComponent";
import { ApiGet } from "../../../helpers/API/ApiData";
import axios from "axios";

export default function VerkaufenBanner() {
  const [newsFeed, setNewsFeed] = useState(false);
  const [informationData, setInformationData] = useState({});

  const getStaticData = async () => {
    await ApiGet(`static/get-all-static`)
      .then((res) => {
        setInformationData(
          res?.data?.payload?.getStatic?.filter((item) => {
            return item?._id === "6482c974e740c9039517f9a5";
          })
        );
      })
      .catch((err) => {
        console.log("erre", err);
      });
  };

  useEffect(() => {
    getStaticData();
  }, []);

  return (
    <>
      <div className="verkaufen-hero-section-content-alignment">
        <div className="grid">
          <div className="grid-items">
            <div className="center-button-text-alignment-global-hero">
              {/* <div>
                <a href="#verkaufen">
                  <p>Jetzt mehr erfahren</p>
                  <div className="page-arrow-alignment">
                    <img src={PageArrow} alt="PageArrow" />
                  </div>
                </a>
              </div> */}
            </div>
          </div>
          <div className="grid-items mobile-view-show">
            <img src={KaufunBanner} alt="KaufunBanner" />
            <img src={KaufunBanner1} alt="KaufunBanner1" />
          </div>
        </div>
        <div className="content-top-alignment">
          <div className="container-md">
            <div className="white-box">
              <h1>Immobilien </h1>
              <h2>Verkauf</h2>
              {/* <p>Beim Verkauf einer Immobilie gibt es </p>
              <p>einiges zu beachten. Wir helfen Ihnen </p>
              <p>gern dabei. Dabei arbeiten wir </p>
              <p>verantwortungsvoll, transparent und </p>
              <p>mit höchster Servicequalität — damit </p>
              <p>Sie sich um nichts kümmern müssen und</p>
              <p>sich am Schluss über einen </p>
              <p>erfolgreichen Verkauf freuen können.</p> */}

              <div className="description-styleaa"
                dangerouslySetInnerHTML={{
                  __html: informationData?.[0]?.description,
                }}
              />

              <NavLink to="/steper">
                <button>Immobilie bewerten lassen</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div >
      <div className="container-md-14">
        <div className="verkauf-all-content-alignment-two">
          {/* <div className="new-feed-button-alignment">
            <button onClick={() => setNewsFeed(!newsFeed)}>News Feeds</button>
          </div> */}
          <div className="text-grid">
            <div className="text-grid-items">
              <h2>
                Deutscher Immobilien Dienst <br />
                <span >Transparent und Verantwortungsvoll</span>
              </h2>
            </div>
            <div className="text-grid-items">

            </div>
          </div>
        </div>
      </div>
      {newsFeed && (
        <AdsComponent newsFeed={newsFeed} setNewsFeed={setNewsFeed} />
      )}
      <div className="container-md-14">
        <div className="button-right-alignment">
          <a href="tel:0421 / 1 67 61 80-0">
            <button>0421 / 1 67 61 80-0</button>
          </a>
        </div>
      </div>
    </>
  );
}
