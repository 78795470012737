import React, { useEffect, useState } from "react";
import "./willkommen.scss";
import LeftIcon from "../../../assets/icons/left-green.svg";
import RightIcon from "../../../assets/icons/right-green.svg";
import WillkommenImage from "../../../assets/images/willkommen.png";
import { NavLink } from "react-router-dom";
import MobileWillkommenImage from "../../../assets/images/mobile-willkommen.png";
import { ApiGet } from "../../../helpers/API/ApiData";
import AdsComponent from "../../../components/adComponent/adsComponent";
export default function Willkommen() {
  const [informationData, setInformationData] = useState({});
  const [newsFeed, setNewsFeed] = useState(false);

  const getInformationData = async () => {
    await ApiGet(`static/get-all-static`)
      .then((res) => {
        console.log("informationData", res);
        setInformationData(
          res?.data?.payload?.getStatic?.filter((item) => {
            return item?._id === "64510695815119014215c482";
          })
        );
      })
      .catch((err) => {
        console.log("erre", err);
      });
  };

  useEffect(() => {
    getInformationData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setNewsFeed(true);
    }, 3000);
  }, []);

  return (
    <div>
      <div id="welcome" className="willkommen-all-content-alignment">
        <div className="container-md-3">
          <div className="grid">
            <div className="grid-items">
              <div className="page-title">
                <h2>{informationData?.[0]?.title}</h2>
                <h5>{informationData?.[0]?.subTitle}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: informationData?.[0]?.description,
                  }}
                ></div>
              </div>
              <div className="globally-button">
                <NavLink to="/steper">
                  <button>Immobilie bewerten lassen</button>
                </NavLink>
              </div>
              <div className="three-col-grid">
                <div className="three-col-grid-items">
                  <div className="globally-button">
                    <NavLink to="/kaufen">
                      <button>KAUFEN</button>
                    </NavLink>
                  </div>
                </div>
                <div className="three-col-grid-items">
                  <div className="two-icon-alignment">
                    <img src={LeftIcon} alt="LeftIcon" />
                    <img src={RightIcon} alt="RightIcon" />
                  </div>
                </div>
                <div className="three-col-grid-items">
                  <div className="globally-button">
                    <NavLink to="/verkaufen">
                      <button>VERKAUFEN</button>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="globally-button">
                <NavLink to="/suchauftrag-step">
                  <button>Suchauftrag erstellen</button>
                </NavLink>
              </div>
            </div>
            {/* {newsFeed && <AdsComponent />} */}
            <div className="grid-items">
              {/* <div className="image-style">
                <img src={informationData[0]?.image[0]} alt="WillkommenImage" />
                <img src={informationData[0]?.image[0]} alt="MobileWillkommenImage" />
              </div> */}

              {newsFeed && <div className="news-feed-details-main">
                <p className="news-feed-text">Immobilien News</p>
                <div>
                  <iframe className="news-feed-detail-iFrame" id="wavepoint_immonewsfeed" style={{ display: "block", overflow: "hidden" }} width="100%" height="auto" frameborder="3"
                    src="https://www.immonewsfeed.de/frame.php?key=2a49c225315e7a71f144967e28370e12_242&anzahl=20&schriftgroesse=13&ueberschriftgroesse=21&ueberschriftfarbe=0000EE&hintergrund=transparent&textfarbe=0000EE&linkmouseover=834bf&linkfarbe=834bf&trennlinienfarbe=e1e1e1&font=open_sans&category=&extendet=&ci_color=%3834bf&immo_key=&text_laenge=80&bildgroesse=medium&bildausrichtung=links&langer_text=&show_image=1&show_datum=1&show_line=5&show_kat=1&template=2">
                    {/* src="https://www.immonewsfeed.de/frame.php?key=2a49c225315e7a71f144967e28370e12_242&anzahl=20&schriftgroesse=13&ueberschriftgroesse=21&ueberschriftfarbe=0000EE&hintergrund=transparent&textfarbe=0000EE&linkmouseover=834bf&linkfarbe=834bf&trennlinienfarbe=e1e1e1&font=open_sans&category=&extendet=&ci_color=%3834bf&immo_key=&text_laenge=80&bildgroesse=medium&bildausrichtung=links&langer_text=&show_image=1&show_datum=1&show_line=5&show_kat=1&template=2"> */}
                  </iframe>
                </div>
              </div>
              }

              {/* <div className="new-feed-button-alignment">
                <button onClick={() => setNewsFeed(!newsFeed)}>News Feeds</button>
              </div> */}
            </div>
          </div>
        </div>

        {newsFeed && (
          <AdsComponent newsFeed={newsFeed} setNewsFeed={setNewsFeed} />
        )}

      </div>
    </div>
  );
}
