import React from 'react'
import './newsFeed.scss';

function NewsFeed() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
    return (
        <div>
            <div className='news-content-alignment-for-page'>
                <div className="container-md">
                    <iframe id="wavepoint_immonewsfeed" width="100%" height="630" frameborder="0" scrolling="no"
                        src="https://www.immonewsfeed.de/frame.php?key=2a49c225315e7a71f144967e28370e12_242&anzahl=20&schriftgroesse=13&ueberschriftgroesse=21&ueberschriftfarbe=000&hintergrund=transparent&textfarbe=000&linkmouseover=1a1a1a&linkfarbe=1a1a1a&trennlinienfarbe=e1e1e1&font=open_sans&category=&extendet=&ci_color=&immo_key=&text_laenge=130&bildgroesse=medium&bildausrichtung=links&langer_text=&show_image=1&show_datum=1&show_line=1&show_kat=1&template=3">
                    </iframe>
                </div>
            </div>
        </div>
    )
}

export default NewsFeed