import React, { useEffect, useRef, useState } from "react";
import HilfreicheForm from "../kaufen/hilfreicheForm";
import Lizenzpartner from "./lizenzpartner";
import PropertyBanner from "./propertyBanner";
import PropertyDetails from "./propertyDetails";
import "./propertyPage.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Contact from "./contact";
export default function PropertyPage() {
  const headingRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location?.aboutProps === "contact" && headingRef.current) {
      setTimeout(() => {
        headingRef.current.scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
    else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const scrollToHeading = () => {
    if (headingRef.current) {
      headingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <PropertyBanner scrollToHeading={scrollToHeading} />
      <PropertyDetails scrollToHeading={scrollToHeading} />
      {/* <Lizenzpartner /> */}
      <span ref={headingRef}></span>
      <div className="contact-us-verkaufen">
        {/* <HilfreicheForm /> */}
        <Contact />
      </div>
    </div>
  );
}

