import React from 'react'
import closeIcon from '../../assets/images/close.png'
import './adsComponent.scss'

export default function AdsComponent(props) {
    const { newsFeed, setNewsFeed } = props;
    return (
        <>

            <div className="willkommen-details-modal-alignment">
                <div className="willkommen-details-modal-wrapper">
                    <div className="willkommen-details-modal-box">
                        <div className="willkommen-details-close-button">
                            <img src={closeIcon} onClick={() => setNewsFeed(!newsFeed)} width="20px" />
                        </div>
                        <iframe id="wavepoint_immonewsfeed" style={{ display: "block" }} width="100%" height="auto" frameborder="3"
                            src="https://www.immonewsfeed.de/frame.php?key=2a49c225315e7a71f144967e28370e12_242&anzahl=20&schriftgroesse=13&ueberschriftgroesse=21&ueberschriftfarbe=000&hintergrund=transparent&textfarbe=000&linkmouseover=1a1a1a&linkfarbe=1a1a1a&trennlinienfarbe=e1e1e1&font=open_sans&category=&extendet=&ci_color=&immo_key=&text_laenge=130&bildgroesse=medium&bildausrichtung=links&langer_text=&show_image=1&show_datum=1&show_line=1&show_kat=1&template=3">
                        </iframe>
                    </div>
                </div>
            </div>
        </>
    )
}