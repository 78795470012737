import React, { useEffect, useState } from "react";
import "./propertyBanner.scss";
import KaufunBanner from "../../../assets/images/proparty-banner.png";
import KaufunBanner2 from "../../../assets/images/proparty-banner2.png";
import PageArrow from "../../../assets/icons/full-page-arrow.svg";
import { NavLink } from "react-router-dom";
import AdsComponent from "../../../components/adComponent/adsComponent";
import { ApiGet } from "../../../helpers/API/ApiData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function PropertyBanner({ scrollToHeading }) {
  const [newsFeed, setNewsFeed] = useState(false);
  const [informationData, setInformationData] = useState({});

  const getStaticData = async () => {
    await ApiGet(`static/get-all-static`)
      .then((res) => {
        setInformationData(
          res?.data?.payload?.getStatic?.filter((item) => {
            return item?._id === "6482f057e740c9039517fc70";
          })
        );
      })
      .catch((err) => {
        console.log("erre", err);
      });
  };

  useEffect(() => {
    getStaticData();
  }, []);
  return (
    <div>
      <div className="property-new-hero-section-content-alignment">
        <div className="grid">
          <div className="grid-items">
            <div className="center-button-text-alignment">
              {/* <div>
                <a href="#property">
                  <p>Jetzt mehr erfahren</p>
                  <div className="page-arrow-alignment">
                    <img src={PageArrow} alt="PageArrow" />
                  </div>
                </a>
              </div> */}
            </div>
          </div>
          <div className="grid-items mobile-view-show ">
            <img src={KaufunBanner} alt="KaufunBanner" />
            <img src={KaufunBanner2} alt="KaufunBanner2" />
          </div>
        </div>
        <div className="content-top-alignment">
          <div className="container-md">
            <div className="white-box">
              <h1>Immobilien </h1>
              <h2> Immobilienverwaltungsservice</h2>
              <p>
                Senken Sie mit unserem Immobilienverwaltungsservice Ihren
                Kosten- und Zeitaufwand bei der Verwaltung Ihres
                Immobilienbestandes.
              </p>

              {/* <div
                className="description-styleaa"
                dangerouslySetInnerHTML={{
                  __html: informationData?.[0]?.description,
                }}
              /> */}
              <Link to={{ pathname: "/property-page", aboutProps: "contact" }}>
                <button
                  onClick={() => scrollToHeading()}
                  style={{
                    position: "relative",
                    zIndex: 99999999999,
                    display: "block",
                  }}
                >
                  {" "}
                  Immobilienverwaltungsservice anfragen
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-md-14">
        <div className="verkauf-all-content-alignment-one">
          {/* <div className="new-feed-button-alignment">
            <button onClick={() => setNewsFeed(!newsFeed)}>News Feeds</button>
          </div> */}
          <div className="text-grid">
            <div className="text-grid-items">
              <h2>
                Deutscher Immobilien Dienst <br />
                <span>Transparent und Vertantwortungsvoll</span>
              </h2>
            </div>
            <div className="text-grid-items"></div>
          </div>
        </div>
      </div>
      {/* {newsFeed && (
        <AdsComponent newsFeed={newsFeed} setNewsFeed={setNewsFeed} />
      )} */}
      <div className="container-md-14">
        <div className="button-right-alignment">
          <a href="tel:0421 / 1 67 61 80-0">
            <button>0421 / 1 67 61 80-0</button>
          </a>
        </div>
      </div>
    </div>
  );
}
