import React, { useEffect, useState } from "react";
import "./Unternehmen.scss";
import KaufunBanner from "../../assets/images/unternehmen-banner.png";
import KaufunBanner2 from "../../assets/images/KaufunBanner2.png";
import PageArrow from "../../assets/icons/full-page-arrow.svg";
import HilfreicheForm from "../kaufen/hilfreicheForm";
import { ApiGet } from "../../helpers/API/ApiData";
import { NavLink } from "react-router-dom";
import AdsComponent from "../../components/adComponent/adsComponent";

export default function Unternehmen() {
  const [companyData, setCompanyData] = useState([]);
  const [newsFeed, setNewsFeed] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getCompanyData = async () => {
    await ApiGet(`company/get-company`)
      .then((res) => {
        console.log("first", res);
        setCompanyData(
          res?.data?.payload?.getCompany?.filter((item) => {
            return item?.isActive === true;
          })
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <div>
      <div className="unternehmen-new-hero-section-content-alignment">
        <div className="grid">
          <div className="grid-items">
            <div className="center-button-text-alignment-global-hero">
              {/* <div>
                <a href="#transparent">
                  <p>Jetzt mehr erfahren</p>
                  <div className="page-arrow-alignment">
                    <img src={PageArrow} alt="PageArrow" />
                  </div>
                </a>
              </div> */}
            </div>
          </div>
          <div className="grid-items mobile-view-show ">
            <img src={KaufunBanner} alt="KaufunBanner" />
            <img src={KaufunBanner2} alt="KaufunBanner2" />
          </div>
        </div>
        <div className="content-top-alignment">
          <div className="container-md">
            <div className="white-box">
              <h1>Deutscher Immobilien Dienst </h1>
              <h2>Unternehmen</h2>

              <p>Gegründet im Jahr 1993 arbeiten wir seit jeher daran, Ihnen </p>
              <p>erstklassige Leistungen im Immobiliensegment anbieten zu können. </p>
              <p>Kompetenz und Zuverlässigkeit gehören dabei zu unseren </p>
              <p>wichtigsten Firmenwerten. Wir sind ein inhabergeführtes </p>
              <p>Unternehmen und haben es uns zur Aufgabe gemacht, die </p>
              <p>Vorstellungen unserer Kunden umzusetzen - egal, ob diese </p>
              <p>simpel oder extravagant ausfallen. Als Immobilienvermittler </p>
              <p>bis hin zum Assetmanagement und Immobilien </p>
              <p>Verwaltungsdienstleister begleiten wir eine Vielzahl von </p>
              <p>Immobilienprojekten, stehen Ihnen in allen Belangen zur Seite </p>
              <p>und sorgen bereits seit über 30 Jahren dafür, dass unsere </p>
              <p>Kunden Ihre Ziele nicht nur erreichen, sondern übertreffen.</p>

              {/* <p>Manager begleiten wir eine Vielzahl von Immobilienprojekten, </p>
              <p>stehen Ihnen in allen Belangen zur Seite und sorgen bereits </p>
              <p>seit über 30 Jahren dafür, dass unsere Kunden Ihre Ziele </p>
              <p>nicht nur erreichen, sondern übertreffen.</p> */}


              <h4>
                Gegründet im Jahr 1993 arbeiten wir seit jeher daran, Ihnen erstklassige
                Leistungen im Immobiliensegment anbieten zu können. Kompetenz und Zuverlässigkeit
                gehören dabei zu unseren wichtigsten Firmenwerten. Wir sind ein inhabergeführtes
                Unternehmen und haben es uns zur Aufgabe gemacht, die Vorstellungen unserer Kunden
                umzusetzen - egal, ob diese simpel oder extravagant ausfallen. Als Makler und
                Property Manager begleiten wir eine Vielzahl von Immobilienprojekten, stehen Ihnen
                in allen Belangen zur Seite und sorgen bereits seit über 30 Jahren dafür, dass
                unsere Kunden Ihre Ziele nicht nur erreichen, sondern übertreffen.
              </h4>
              <NavLink to="/suchauftrag-step">
                <button>Anfrage starten</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div id="transparent" className="container-md-14">
        <div className="verkauf-all-content-alignment">
          {/* <div className="new-feed-button-alignment">
            <button onClick={() => setNewsFeed(!newsFeed)}>News Feeds</button>
          </div> */}

          <div className="text-grid">
            <div className="text-grid-items">
              <h2>
                Deutscher Immobilien Dienst <br />
                <span>Transparent und Vertantwortungsvoll</span>
              </h2>
            </div>
            <div className="text-grid-items"></div>
          </div>
        </div>
      </div>

      {/* {newsFeed && (
        <AdsComponent newsFeed={newsFeed} setNewsFeed={setNewsFeed} />
      )} */}

      <div className="container-md-14">
        <div className="unternehmen-all-content-alignment-for-page">
          <div className="left-content">
            <NavLink to="/verkaufen">
              <span>VERKAUFEN</span>
            </NavLink>
            <NavLink to="/kaufen">
              <span>KAUFEN</span>
            </NavLink>
            <NavLink to="/property-page">
              <span>IMMOBILIENVERWALTUNGSSERVICE</span>
            </NavLink>
          </div>
          <div className="right-content">
            <a href="tel:0421 / 1 67 61 80-0">
              <button>0421 / 1 67 61 80-0</button>
            </a>
          </div>
        </div>
      </div>
      <div className="team-section-all-content-alignment">
        <div className="container-md-17">
          <div className="all-grid-content-alignment">
            {companyData?.slice(0, 4).map((item, index) => {
              return (
                <div className="grid">
                  <div className="grid-items">
                    <div className="image-style">
                      <img src={item?.userImage[0]} alt="TeamImage" />
                    </div>
                  </div>
                  <div className="grid-items">
                    <h6>{item?.name}</h6>
                    <h5>{item?.occupationRole}</h5>
                    <p>{item?.description}</p>
                    <span>Telefon: {item?.phone}</span>
                    <span>E-Mail: {item?.email}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="contact-us">
        <HilfreicheForm />
      </div>
    </div>
  );
}
