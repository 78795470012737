import React, { useEffect, useRef } from "react";
import HilfreicheForm from "../kaufen/hilfreicheForm";
import "./verkaufen.scss";
import VerkaufenBanner from "./verkaufenBanner/index";
import VorteileDetails from "./vorteileDetails";
import { useLocation } from "react-router-dom";
export default function Verkaufen() {
  const headingRef = useRef(null);
  const headingRef2 = useRef(null);
  const location = useLocation();

  const scrollToHeading = () => {
    if (location?.aboutProps === "Erfolgsabhängig") {
      if (headingRef.current) {
        headingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    else if (location?.aboutProps === "provisionsfrei") {
      if (headingRef2.current) {
        headingRef2.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  // useEffect(() => {
  // window.scrollTo({
  //   top: headingRef.current.offsetTop,
  //   left: 0,
  //   behavior: "smooth",
  // });
  // const element = document.getElementById("handling1");
  // if (element) {
  //   window.scrollTo({
  //     top: element.offsetTop,
  //     behavior: "smooth",
  //   });
  // }
  // scrollToHeading();
  // }, []);
  return (
    <div>
      <VerkaufenBanner />
      <VorteileDetails scrollToHeading={scrollToHeading} headingRef={headingRef} headingRef2={headingRef2} />
      <div className="contact-us-verkaufen">
        <HilfreicheForm />
      </div>
    </div>
  );
}
