import React, { useState } from "react";
import "./firstStep.scss";
export default function FirstStep(props) {
  const {
    inputValue,
    setInputValue,
    step,
    setStep,
    width,
    setWidth,
    errors,
    setErrors,
  } = props;

  const [selectData, setSelectData] = useState([])

  const handleButton = (e, data) => {
    if (inputValue?.manageProperty?.length > 0 && inputValue?.manageProperty.includes(data)) {
      setSelectData(selectData.filter(item => item !== data))
      setInputValue({ ...inputValue, ["manageProperty"]: inputValue?.manageProperty.filter(item => item !== data) });
    }
    else {
      setSelectData([...selectData, data])
      setInputValue({ ...inputValue, ["manageProperty"]: [...inputValue?.manageProperty, data] });
    }
    setErrors({ ...errors, ["manageProperty"]: "" });
  };

  const validation = () => {
    let isFormValid = true;
    let errors = {};

    if (inputValue && !inputValue.manageProperty) {
      isFormValid = false;
      errors["manageProperty"] = "Bitte Objekt auswählen";
    }

    setErrors(errors);
    return isFormValid;
  };

  const handleOnNext = () => {
    setInputValue({ ...inputValue, ["manageProperty"]: selectData });
    if (validation()) {
      setStep(2);
      // if (inputValue?.manageProperty === "Business" || inputValue?.manageProperty === "BusinessProperty") {
      //   setStep(3);
      // } else {
      //   setStep(2);
      // }
      setWidth(width + 20);
    }
  };

  return (
    <div>
      <div className="property-management-first-steper-alignment">
        <div className="section-title">
          <h6>Immobilienverwaltungsservice</h6>
          <p>Welche Unterstützung brauchen Sie ?</p>
        </div>
        <div className="button-alignment">
          <button
            name="manageProperty"
            onClick={(e) => handleButton(e, "house")}
          >
            Vermietung / Verkauf
          </button>
          <button
            name="manageProperty"
            onClick={(e) => handleButton(e, "Property")}
          >
            Mieterbetreuung
          </button>
          <button
            name="manageProperty"
            onClick={(e) => handleButton(e, "Business")}
          >
            Objektbegehung
          </button>
          <button
            name="manageProperty"
            onClick={(e) => handleButton(e, "Business")}
          >
            Instandhaltungsüberwachung
          </button>
        </div>
      </div>
      <span
        style={{
          color: "red",
          top: "5px",
          fontSize: "12px",
        }}
      >
        {errors["manageProperty"]}
      </span>
      <div className="steper-right-button-alignment">
        <button onClick={() => handleOnNext()}>Weiter</button>
      </div>
    </div>
  );
}
