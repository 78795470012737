import React, { useEffect, useState } from "react";
import "./kaufenHeroSection.scss";
import KaufunBanner from "../../../assets/images/kufun-banner.png";
import KaufunBanner2 from "../../../assets/images/mobile-banner2.png";
import PageArrow from "../../../assets/icons/full-page-arrow.svg";
import { NavLink } from "react-router-dom";
import { ApiGet } from "../../../helpers/API/ApiData";

export default function KaufenHeroSection() {
  const [informationData, setInformationData] = useState({});


  const getStaticData = async () => {
    await ApiGet(`static/get-all-static`)
      .then((res) => {
        console.log("informationData", res);
        setInformationData(
          res?.data?.payload?.getStatic?.filter((item) => {
            return item?._id === "6482cb11e740c9039517f9bf";
          })
        );
      })
      .catch((err) => {
        console.log("erre", err);
      });
  };

  useEffect(() => {
    getStaticData();
  }, []);

  return (
    <div>
      <div className="kaufen-hero-section-content-alignment">
        <div className="grid">
          <div className="grid-items">
            <div className="center-button-text-alignment-global-hero">
              {/* <div>
                <a href="#seek">
                  <p>Jetzt mehr erfahren</p>
                  <div className="page-arrow-alignment">
                    <img src={PageArrow} alt="PageArrow" />
                  </div>
                </a>
              </div> */}
            </div>
          </div>
          <div className="grid-items mobile-view-show ">
            <img src={KaufunBanner} alt="KaufunBanner" />
            <img src={KaufunBanner2} alt="KaufunBanner2" />
          </div>
        </div>
        <div className="content-top-alignment">
          <div className="container-md">
            <div className="white-box">
              <h1>Immobilien </h1>
              <h2>Kaufen</h2>

              {/* <p>Entscheiden Sie sich für den Kauf  </p>
              <p>einer Immobilie, geht damit eine große </p>
              <p>Verantwortung einher — sowohl auf  </p>
              <p>finanzieller als auch auf organisatorischer </p>
              <p>Ebene. Einen großen Teil davon können Sie  </p>
              <p>jedoch beruhigt an uns abgeben. Wir  </p>
              <p>unterstützen Sie während des gesamten  </p>
              <p>Kaufprozesses und darüber hinaus so gut </p>
              <p>wir können und nehmen Ihnen so den Stress, </p>
              <p>den ein Immobilienkauf sonst bedeutet.</p> */}

              <div className="description-styleaa"
                dangerouslySetInnerHTML={{
                  __html: informationData?.[0]?.description,
                }}
              />

              <NavLink to="/suchauftrag-step">
                <button>Suchauftrag erstellen</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
