import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./deutscher.scss";
import AdsComponent from "../../../components/adComponent/adsComponent";
export default function Deutscher() {
  const [newsFeed, setNewsFeed] = useState(false);

  return (
    <div>
      <div className="deutscher-all-content-alignment">
        <div className="container-md-5">
          {/* <div className="new-feed-button-alignment">
            <button onClick={() => setNewsFeed(!newsFeed)}>News Feeds</button>
          </div> */}
          <div className="text-grid">
            <div className="text-grid-items">
              <h2>Deutscher Immobilien Dienst </h2>
              <p>
                Immobilien sind unser Fachgebiet - <br />
                mit unserem Wissen unterstützen wir Sie <br /> das Objekt Ihrer
                Zukunft zu finden.
              </p>
            </div>
            {/* {newsFeed && (
              <AdsComponent newsFeed={newsFeed} setNewsFeed={setNewsFeed} />
            )} */}
            <div className="text-grid-items">
              <NavLink to="/kontakt">
                <button>KONTAKT</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
